.au_left {
  img {
    width: 70%;
  }
}

.bottom_top_container {
  display: flex;
  gap: 5rem;
  .bottom_top_element {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    .bottom_top_element_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 3rem;
      }
    }
  }
  h4 {
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0;
  }
}

.au_bottom {
  display: flex;
  flex-direction: column;
  .au_bottom_left {
    display: flex;
    padding: 1rem 0;
    h3 {
      width: 20%;
      margin-bottom: 0;
    }
    h5 {
      width: 80%;
      margin-bottom: 0;
    }
  }
}

.au_right {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  ul {
    list-style-image: url("../../assets/check.png");
  }
}

@media screen and (max-width: 1050px) {
  .bottom_top_container {
    flex-direction: column;
  }

  .bottom_top_container {
    gap: 0;
  }

  .au_bottom_left {
    flex-direction: column;
    h3 {
      padding-bottom: 1rem;
    }
  }
  .au_bottom .au_bottom_left h3 {
    width: 100%;
    text-align: center;
  }
  .au_bottom .au_bottom_left h5 {
    width: 100%;
    text-align: center;
  }

  .au_left img {
    width: 100%;
  }

  .au_right {
    align-items: center;
    padding-top: 2rem;
  }
}
