.cmt_right_image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .cmt_right_image img:nth-child(1) {
    width: 70%;
  }
}

.cmt_right_image img:nth-child(1) {
  //   z-index: 1;
  width: 70%;
}

.cmt_s3_left {
  // width: 50%;
  .cmt_s3_element {
    display: flex;
  }
}
.cmt_right {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
}

.cmt_right img:nth-child(1) {
  z-index: 1;
  width: 45%;
}

.cmt_right img:nth-child(2) {
  width: 65%;
  object-fit: contain;
  position: absolute;
}

.cmt_s4_right {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
}

.cmt_s4_right img:nth-child(1) {
  width: 45%;
}

.cmt_s4_right img:nth-child(2) {
  width: 45%;
  object-fit: contain;
  position: absolute;
}
