.s5_container {
  background-image: url(../../../assets/sectionfive/sectionfive.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
}

.s5_right {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  img {
    position: absolute;
  }
  img:nth-child(1) {
    transform: translate(-8rem, -6rem);
    animation: scale_s5_1 5s ease-in-out infinite;
  }
  img:nth-child(2) {
    transform: translate(2rem, -9rem);
    animation: scale_s5_2 3s ease-in-out infinite;
  }
  img:nth-child(3) {
    transform: translate(9rem, -8rem);
    animation: scale_s5_3 5s ease-in-out infinite;
  }
  img:nth-child(4) {
    transform: translate(2rem, -2rem);
    animation: scale_s5_4 3s ease-in-out infinite;
  }
  img:nth-child(5) {
    transform: translate(9rem, -1rem);
    animation: scale_s5_5 4s ease-in-out infinite;
  }
  img:nth-child(6) {
    transform: translate(-2rem, 7rem);
    animation: scale_s5_6 3s ease-in-out infinite;
  }
  img:nth-child(7) {
    transform: translate(-11rem, 4rem);
    animation: scale_s5_7 4s ease-in-out infinite;
  }
  img:nth-child(8) {
    transform: translate(10rem, 8rem);
    animation: scale_s5_8 3s ease-in-out infinite;
  }
}

@keyframes scale_s5_1 {
  0% {
    transform: translate(-8rem, -6rem) scale(1);
  }
  50% {
    transform: translate(-8rem, -6rem) scale(1.1);
  }
  100% {
    transform: translate(-8rem, -6rem) scale(1);
  }
}

@keyframes scale_s5_2 {
  0% {
    transform: translate(2rem, -9rem) scale(1);
  }
  50% {
    transform: translate(2rem, -9rem) scale(1.1);
  }
  100% {
    transform: translate(2rem, -9rem) scale(1);
  }
}
@keyframes scale_s5_3 {
  0% {
    transform: translate(9rem, -8rem) scale(1);
  }
  50% {
    transform: translate(9rem, -8rem) scale(1.1);
  }
  100% {
    transform: translate(9rem, -8rem) scale(1);
  }
}
@keyframes scale_s5_4 {
  0% {
    transform: translate(2rem, -2rem) scale(1);
  }
  50% {
    transform: translate(2rem, -2rem) scale(1.1);
  }
  100% {
    transform: translate(2rem, -2rem) scale(1);
  }
}
@keyframes scale_s5_5 {
  0% {
    transform: translate(9rem, -1rem) scale(1);
  }
  50% {
    transform: translate(9rem, -1rem) scale(1.1);
  }
  100% {
    transform: translate(9rem, -1rem) scale(1);
  }
}
@keyframes scale_s5_6 {
  0% {
    transform: translate(-2rem, 7rem) scale(1);
  }
  50% {
    transform: translate(-2rem, 7rem) scale(1.1);
  }
  100% {
    transform: translate(-2rem, 7rem) scale(1);
  }
}
@keyframes scale_s5_7 {
  0% {
    transform: translate(-11rem, 4rem) scale(1);
  }
  50% {
    transform: translate(-11rem, 4rem) scale(1.1);
  }
  100% {
    transform: translate(-11rem, 4rem) scale(1);
  }
}
@keyframes scale_s5_8 {
  0% {
    transform: translate(10rem, 8rem) scale(1);
  }
  50% {
    transform: translate(10rem, 8rem) scale(1.1);
  }
  100% {
    transform: translate(10rem, 8rem) scale(1);
  }
}

.crusel_drag {
  display: flex;
  align-items: center;
  gap: 1rem;
  img {
    object-fit: contain;
  }
  p {
    margin-bottom: 0;
  }
}

.s5_left {
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.slick-dots {
  width: initial !important;
}

.slick-dots li.slick-active button:before {
  color: #fff !important;
}

.slick-dots li button:before {
  color: #fff !important;
}

.crusel_container {
  img {
    padding: 1rem 0;
  }
}

.s5_all_image {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 70%;
    padding: 2rem;
  }
}

@media screen and (max-width: 1050px) {
  .s5_all_image img {
    width: 100%;
    padding: 1rem;
  }
}
