.my_loder_center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
}

.my_loder_center p {
  font-weight: 600;
  font-size: 1.1rem;
}

.coin_loader {
  transform: rotate(0deg);
  animation: loader 4s ease-in-out infinite;
}

@keyframes loader {
  0% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
