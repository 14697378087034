.so_left {
  height: 100%;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.so_left img:nth-child(1) {
  width: 70%;
}

.so_left img:nth-child(2) {
  object-fit: contain;
  position: absolute;
  width: 70%;
  transform: translate(85%, -10%);
  animation: sectionOne 4s ease-in-out infinite;
}

.so_left img:nth-child(3) {
  object-fit: contain;
  position: absolute;
  width: 80%;
  z-index: -1;
  // transform: translate(45%, -10%);
}

.so_right {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  .so_element_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .view_more_btn {
    color: var(--primary);
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: var(--trans);

    p {
      margin-bottom: 0;
      font-weight: 600;
    }
    .view_more_btn_right {
      margin: 0 0.2rem;
      transition: var(--trans);
    }
    &:hover {
      .view_more_btn_right {
        transform: translateX(5px);
      }
    }
  }
}

.so_inner_element {
  display: flex;
  gap: 1rem;
  .so_element_image {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      background: var(--primary_bg_dark);
      padding: 1rem;
      border-radius: 8px;
    }
  }
  .so_element_content {
    h5 {
      font-weight: 900;
    }
    p {
      margin-bottom: 0;
    }
  }
}

@keyframes sectionOne {
  0% {
    transform: translate(85%, -10%);
  }
  50% {
    transform: translate(85%, 0%);
  }
  100% {
    transform: translate(85%, -10%);
  }
}

@media screen and (max-width: 1050px) {
  .so_left img:nth-child(1) {
    width: 85%;
  }

  .so_left img:nth-child(3) {
    width: 100%;
  }

  .so_left img:nth-child(2) {
    width: 35%;
  }

  .so_right {
    gap: 0.5rem;
  }
}
