.features_tags {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.features_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.features_images_left {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.features_images_left img:nth-child(1) {
  z-index: 1;
  width: 70%;
}

.features_wrapper_two {
  flex-direction: row-reverse;

  .features_content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .features_images_left {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
  }

  .features_images_left img:nth-child(1) {
    z-index: 1;
    width: 70%;
  }
}

.features_tags_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
}

.feature_section_padding {
  padding: 7rem 0rem 7rem 0rem;
}

.feature_link {
  text-decoration: none;
}

@media screen and (max-width: 1050px) {
  .features_tags {
    flex-wrap: wrap;
  }
  .features_content {
    align-items: center !important;
    text-align: center;
  }

  .features_wrapper_two {
    flex-direction: column-reverse;
  }
}
