.login_title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    h3 {
      font-weight: 800;
    }
  }
  
  .login_top_padding {
    padding-top: 15vh;
    &.bottom {
      margin-bottom: -5rem;
    }
  }
  
  .login_body {
    position: relative;
  }
  
  .cc_form {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    z-index: 1;
    &.register {
      height: 100vh;
    }
    .left_img {
      position: absolute;
      left: 0;
      z-index: 0;
      width: 50%;
    }
    .right_img {
      position: absolute;
      right: 0;
      z-index: 0;
      width: 50%;
    }
  }
  .flex-container {
    display: flex;
}

.flex-child {
    flex: 1;
   
}  
.copybtn{
    border: 1px solid black;
    border-radius: 20px;
    padding: 2px 15px 2px 15px;
    margin-top: 10px;
    font-weight: 600;
    cursor: pointer;
}
.metamaskid, .copybtn{
    font-weight: 700;
    font-size: 12px;
    display: inline;
}

.flex-child:first-child {
    margin-right: 20px;
} 
  .cc_form_container {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
    padding: 2rem;
    background: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    border-radius: 12px;
    gap: 1rem;
    width: 40%;
    padding: 2rem;
    .login_new_register {
      margin-bottom: 0;
      span {
        cursor: pointer;
        color: var(--primary);
      }
    }
    .cc_form_header {
      h5 {
        font-weight: 800;
      }
      p {
        margin-bottom: 0;
      }
    }
  }
  
  .cc_form_id,
  .cc_form_pwd {
    display: flex;
    flex-direction: column;
  }
  .cc_form_header h6{

      font-weight: 800;
    }

  .cc_form_id {
    input {
      border: none;
      background-color: #eaeaea;
      padding: 10px 15px;
      border-radius: 4px;
    }
  }
  
  .cc_form_pwd {
    input {
      border: none;
      background-color: #eaeaea;
      padding: 10px 15px;
      border-radius: 4px;
    }
  }
  
  .auth_bg {
    background-color: var(--primary_bg);
  }
  

.stepstobuybtn{
    border: 1px solid black;
    border-radius: 10px;
    padding: 5px 20px 5px 20px;
    margin-top: 10px;
    font-weight: 600;
}
.stepsnumber{
    color: darkgray;
    font-style: italic;
    font-size: 20px;
    
}


.metamaskidrow{
   margin-top: 4%;
   text-align: left;
}

.stepsnumber, .stepsparagraph{
    // font-size: 14px;
    // text-align:center;
    display: inline;
}
.stepsparagraph{
    font-size: 14px;
    text-align: left;
}

.contracttxt{
    font-size: 12px;
}



  @media screen and (max-width: 1050px) {
    .cc_form_container{
        margin-top: 60% !important;
    }
    .flex-container {
        display: initial;
    }
    .left_img {
      display: none;
    }
    .right_img {
      display: none;
    }
  
    .cc_form_container {
      width: 92%;
    }
  
    .cc_form {
      height: 70vh;
      &.register {
        height: 75vh;
      }
    }
  }
  