.st_main_container {
  background-image: url(../../../assets/sectionfive/sectionfive.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .st_container {
    color: #fff;
    display: flex;
    .my_grid_col {
      .st_left {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 3rem 0;
        justify-content: center;
        height: 100%;
        .st_left_icon {
          display: flex;
          align-items: center;
          gap: 2rem;
        }
      }
    }

    .my_grid_col {
      .st_right {
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          position: absolute;
        }
        img:nth-child(1) {
          transform: translate(-12rem, -3rem);
          animation: scale1 3s ease-in-out infinite;
        }
        img:nth-child(2) {
          transform: translate(-13rem, 3rem);
          animation: scale2 4s ease-in-out infinite;
        }
        img:nth-child(3) {
          transform: translate(-6rem, 0rem);
          animation: scale3 3s ease-in-out infinite;
        }
        img:nth-child(4) {
          transform: translate(-6rem, 5rem);
          animation: scale4 5s ease-in-out infinite;
        }
        img:nth-child(5) {
          transform: translate(1rem, -3rem);
          animation: scale5 3s ease-in-out infinite;
        }
        img:nth-child(6) {
          transform: translate(0rem, 4rem);
          animation: scale6 5s ease-in-out infinite;
        }
        img:nth-child(7) {
          transform: translate(8rem, -3rem);
          animation: scale7 3s ease-in-out infinite;
        }
        img:nth-child(8) {
          transform: translate(7rem, 4rem);
          animation: scale8 4s ease-in-out infinite;
        }
      }
    }
  }
}

@keyframes scale1 {
  0% {
    transform: translate(-12rem, -3rem) scale(1);
  }
  50% {
    transform: translate(-12rem, -3rem) scale(1.1);
  }
  100% {
    transform: translate(-12rem, -3rem) scale(1);
  }
}

@keyframes scale2 {
  0% {
    transform: translate(-13rem, 3rem) scale(1);
  }
  50% {
    transform: translate(-13rem, 3rem) scale(1.1);
  }
  100% {
    transform: translate(-13rem, 3rem) scale(1);
  }
}

@keyframes scale3 {
  0% {
    transform: translate(-6rem, 0rem) scale(1);
  }
  50% {
    transform: translate(-6rem, 0rem) scale(1.1);
  }
  100% {
    transform: translate(-6rem, 0rem) scale(1);
  }
}

@keyframes scale4 {
  0% {
    transform: translate(-6rem, 5rem) scale(1);
  }
  50% {
    transform: translate(-6rem, 5rem) scale(1.1);
  }
  100% {
    transform: translate(-6rem, 5rem) scale(1);
  }
}

@keyframes scale5 {
  0% {
    transform: translate(1rem, -3rem) scale(1);
  }
  50% {
    transform: translate(1rem, -3rem) scale(1.1);
  }
  100% {
    transform: translate(1rem, -3rem) scale(1);
  }
}

@keyframes scale6 {
  0% {
    transform: translate(0rem, 4rem) scale(1);
  }
  50% {
    transform: translate(0rem, 4rem) scale(1.1);
  }
  100% {
    transform: translate(0rem, 4rem) scale(1);
  }
}

@keyframes scale7 {
  0% {
    transform: translate(8rem, -3rem) scale(1);
  }
  50% {
    transform: translate(8rem, -3rem) scale(1.1);
  }
  100% {
    transform: translate(8rem, -3rem) scale(1);
  }
}

@keyframes scale8 {
  0% {
    transform: translate(7rem, 4rem) scale(1);
  }
  50% {
    transform: translate(7rem, 4rem) scale(1.1);
  }
  100% {
    transform: translate(7rem, 4rem) scale(1);
  }
}

.st_all_right {
  img {
    width: 100%;
  }
}

@media screen and (max-width: 1050px) {
  .st_main_container {
    background-image: url(../../../assets//sectiontwo/mob.png);
  }

  .st_all_right img {
    padding: 1rem 0;
  }
}
