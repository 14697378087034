.header_bg {
  background: var(--primary_bg);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  background: var(--primary_bg);
  display: flex;
  position: fixed;
  transition: var(--trans);
  width: 100%;
  z-index: 2;
}

.header_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}

.header_back_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .header_back_icon {
    font-size: 1.5rem;
    transition: var(--trans);
  }
  &:hover .header_back_icon {
    transform: translateX(-5px);
    color: var(--primary);
  }
  h5 {
    margin-bottom: 0;
    font-weight: 600;
    padding: 0 1rem;
  }
}

.header_left {
  display: flex;
  color: #000;
  align-items: center;
}

@media screen and (max-width: 1050px) {
  .header_left {
    h5 {
      display: none;
    }
  }
}
