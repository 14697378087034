@mixin all_btns_buttons {
  color: #fff;
  padding: 0.625rem 1.875rem;
  border: none;
  border-radius: 4px;
  line-height: 24px;
  font-weight: 600;
}

@mixin flex_justify_align_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
