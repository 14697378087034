@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&family=Roboto:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");

:root {
  --primary: #0a6ca9;
  --primary_light: #bdd1e3;
  --primary_dark: #000342;
  --primary_hover: #237bb2;
  --primary_bg: #eaeaf1;
  --primary_bg_dark: #e5e5eb;
  --secondary: #ff7262;

  --px18: 1.125rem;
  --px24: 1.5rem;
  --px36: 2.25rem;
  --px42: 2.625rem;
  --px52: 3.25rem;
  --px65: 3.75rem;

  --box_shadow: 0px 44px 61px -9px rgba(0, 0, 0, 0.11);
  --trans: 0.3s ease-in-out;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

textarea:focus,
input:focus,
button:focus {
  outline: none;
}

// commen css start

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

h1 {
  font-size: var(--px65);
}

h2 {
  font-size: var(--px52);
}

h3 {
  font-size: var(--px42);
}

h4 {
  font-size: var(--px36);
}

h5 {
  font-size: var(--px24);
}

h6 {
  font-size: var(--px18);
}

.primary_btn {
  cursor: pointer;
  background: linear-gradient(90deg, #000342 -4.76%, #0a6ca9 104.76%);
  border: none;
  color: #fff;
  font-size: 1rem;
  border-radius: 4px;
  padding: 0.625rem 1.875rem;
  text-decoration: none;
  white-space: nowrap;
  transition: var(--trans);
  &:hover {
    background: linear-gradient(90deg, #000342 -4.76%, #0a6ca9 154.76%);
    transition: var(--trans);
    color: #fff;
  }
}

.secondary_btn {
  border: 1px solid var(--primary_dark);
  color: var(--primary_dark);
  font-size: 1rem;
  border-radius: 4px;
  padding: 0.625rem 1.875rem;
  &.feature {
    padding: 0.5rem 0.5rem;
  }
}

.common_top_padding {
  padding-top: 8vh;
}

.common_section_padding {
  padding: 7rem 0rem 0rem 0rem;
  &.bottom {
    padding: 7rem 0rem 7rem 0rem;
  }
  &.no_top {
    padding: 0rem 0rem 0rem 0rem;
  }
}

.font_weight_600 {
  font-weight: 600;
}

.font_weight_800 {
  font-weight: 800;
}

.cc_text_transform {
  text-transform: uppercase;
  color: var(--primary_dark);
  font-weight: 600;
  &.white {
    color: #fff;
  }
}

.title_padding {
  padding: 1rem 0 2rem 0;
}

.flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

// commen css end

@media screen and (max-width: 1050px) {
  .margin_top_mob {
    padding: 2rem 0rem 0rem 0rem;
  }

  .common_section_padding {
    padding: 4rem 0rem 0rem 0rem;
    &.bottom {
      padding: 4rem 0rem 4rem 0rem;
    }
  }

  .common_heading {
    h6,
    h3 {
      text-align: center;
      &.h3_plus {
        font-size: 2rem;
      }
    }
    h6 {
      margin-top: 2rem;
    }

    .view_more_btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .mob_flex_center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
