.videobtn{
    width: 25%;
    height: 25%;
    margin-left: 10%;
    cursor: pointer;
}
.videodiv{
    display: flex;
}

.cc_form_container{
    margin-top: 3%;
}

@media screen and (max-width: 1050px) {
    .videobtn{
        width: 20%;
        height: 20%;
        margin-left: 10%;
    }
    .cc_form_container{
        margin-top: 10%;
    }
}

// @media screen and (min-width: 1920px) {
//     .videobtn{
//         width: 15%;
//         height: 15%;
//         margin-left: 10%;
//     }
//     .cc_form_container{
//         margin-top: 5%;
//     }
// }