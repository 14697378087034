.cc_s2_body {
  .cc_s2_bg {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary_light);
    width: 80%;
    margin: auto;
    padding: 2rem;
    border-radius: 12px;

    .cc_s2_e1 {
      display: flex;
      padding: 1rem;
      background: var(--primary_bg);
      transform: translate(-4rem, 0px);
      box-shadow: var(--box_shadow);
      filter: drop-shadow(0px 11px 44px rgba(0, 0, 0, 0.2));
      border-radius: 12px;
      width: 50%;

      .cc_s2_e1_content {
        h6 {
          white-space: nowrap;
        }
      }
    }
    .cc_s2_e2 {
      display: flex;
      padding: 1rem;
      background: var(--primary_bg);
      box-shadow: var(--box_shadow);
      filter: drop-shadow(0px 11px 44px rgba(0, 0, 0, 0.2));
      border-radius: 12px;
      width: 50%;
    }
    .cc_s2_e3 {
      display: flex;
      padding: 1rem;
      background: var(--primary_bg);
      transform: translate(4rem, 0px);
      box-shadow: var(--box_shadow);
      filter: drop-shadow(0px 11px 44px rgba(0, 0, 0, 0.2));
      border-radius: 12px;
      width: 50%;
    }
  }
}

.cc_timeline {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    padding: 2rem 0;
    width: 70%;
  }
}

.coin_hero_right img:nth-child(3) {
  width: 30%;
  object-fit: contain;
  position: absolute;
  left: 23%;
  top: 7%;
}

.coin_right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.coin_left_hand {
  z-index: 1;
}

.coin_btns {
  display: flex;
  gap: 1rem;
}

.airdrop_btns{
  
 
    // display: "flex";
    // flex-direction:"column";
    // align-items:center;
    // justify-content: center;

 
  }

.airdropbtn_background{
  width: 40%;
  // height: 100%;
  // margin-top: 5%;
  margin-left: 28%;
  cursor: pointer;
  }
.coinsdescription{
  margin-top: 3%;
}
.airdropbtn{
  margin-top: 3%;
}
.cc_s3_body {
  align-items: center;
  justify-content: center;
  img {
    width: 70%;
    padding: 2rem 0;
  }
}

.cc_s4_left {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}

.cc_s4_img {
  width: 70%;
}

.cc_s2_img {
  padding: 0 0.5rem;
}

.coin_coin_img {
  display: flex;
  align-items: center;
  justify-content: end;
}

.cc_s3_body_top {
  display: flex;
  gap: 10rem;
  position: relative;
  justify-content: center;
  .top_img1 {
    position: absolute;
    width: 8%;
    object-fit: contain;
    transform: translate(-13rem, 7rem);
  }
  .top_img2 {
    position: absolute;
    width: 8%;
    object-fit: contain;
    transform: translate(14rem, 7rem);
  }
}

.cc_s3_body_bottom {
  position: relative;

  .bottom_img1 {
    position: absolute;
    width: 8%;
    object-fit: contain;
    transform: translate(-26rem, -6rem);
  }
  .bottom_img2 {
    position: absolute;
    width: 8%;
    object-fit: contain;
    transform: translate(0rem, -2rem);
  }
}

.cc_s3_body_top1,
.cc_s3_body_bottom1 {
  width: 16rem;
  h1 {
    font-weight: 900;
    font-size: 5rem;
    margin-bottom: 0;
    color: var(--primary_light);
  }
}

.cc_s3_body_bottom {
  display: flex;
  justify-content: center;
  gap: 10rem;
  .cc_s3_body_bottom1 {
    width: 16rem;
  }
}

.animals_btns {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  border-radius: 4px;
  .ani {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    margin: 0 3rem;
    img {
      object-fit: contain;
      width: 100%;
      margin: 0 0.5rem;
    }
    p {
      margin-bottom: 0;
    }
  }
  .animals_btns1 {
    padding: 0.5rem 1rem;
    border-right: 1px solid #000;
  }
}

@media screen and (max-width: 1050px) {
  .cc_s2_bg {
    flex-direction: column;
    gap: 2rem;
    .cc_s2_e1 {
      transform: initial !important;
    }
    .cc_s2_e3 {
      transform: initial !important;
    }
  }

  .cc_s3_body img {
    width: 100%;
  }

  .cc_timeline img {
    width: 100%;
  }

  .cc_s2_body .cc_s2_bg {
    width: 80%;
    margin-top: 2rem;
  }

  .cc_s2_body .cc_s2_bg .cc_s2_e1 {
    width: 150%;
  }

  .cc_s2_body .cc_s2_bg .cc_s2_e2 {
    width: 150%;
  }
  .cc_s2_body .cc_s2_bg .cc_s2_e3 {
    width: 150%;
  }

  .cc_s3_body_bottom {
    flex-wrap: wrap;
    gap: 0;
  }
  .cc_s3_body_top {
    flex-wrap: wrap;
    gap: 0;
  }
  .bottom_img1,
  .bottom_img2,
  .top_img1,
  .top_img2 {
    display: none;
  }

  .cc_s3_body_top1,
  .cc_s3_body_bottom1 {
    width: initial;
  }

  .coin_hero_right {
    padding: 0;
  }
  .coin_right {
    align-items: center;
    padding-top: 2rem;
  }

  .cc_timeline {
    text-align: center;
  }

  .cc_s4_img {
    width: 100%;
    margin: 1rem 0;
  }

  .cc_s4_left {
    text-align: center;
    align-items: center;
  }

  .coin_btns {
    flex-wrap: wrap;
    white-space: nowrap;
    justify-content: center;
  }



  .coin_hero_right img:nth-child(3) {
    left: 15%;
  }

  .coin_coin_img {
    justify-content: center;
  }

  .animals_btns .ani {
    margin: 0 2.6rem;
  }
  .coinsdroping{
    margin-top: 5%;
  }


  .airdropbtn_background{
    width: 60%;
    margin-left: 5%;
    cursor: pointer;
    }

}
