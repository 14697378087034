.s7_left {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.s7_right {
  display: flex;
  justify-content: center;
  flex-direction: column;
  img {
    width: 70%;
  }
}

@media screen and (max-width: 1050px) {
  .s7_left {
    align-items: center;
    text-align: center;
  }

  .s7_right {
    padding-top: 2rem;
    align-items: center;
  }
}
