@import "../../_mixin.scss";

.my_navbar_logo_div {
  img {
    width: 70%;
  }
}

.my_navbar {
  background: var(--primary);
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  font-size: 1.2rem;
}

.scroll_my_navbar {
  height: 10vh;
  background: var(--primary_bg);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  transition: var(--trans);
}

.my_navbar_main {
  z-index: 1;
  background: var(--primary);
  display: flex;
  border-bottom: 0.1px solid rgba(114, 114, 114, 0.35);
  position: fixed;
  width: 100%;
}

.scroll_my_navbar_main {
  z-index: 2;
  background: var(--primary_bg);
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  position: fixed;
  width: 100%;
  transition: var(--trans);
}

.my_navbar button {
  white-space: nowrap;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 0.5rem;
  list-style: none;
  text-align: center;
  // width: 70vw;
  justify-content: center;

  margin-bottom: 0 !important;
}

.my_navbar_logo {
  // width: 100%;
  img {
    /* margin-left: 1em; */
    object-fit: contain;
    // width: 11.125rem;
    transition: var(--trans);
  }
}

.menu_ion_icon {
  color: white;
}

.nav-item {
  color: #fff;
  display: flex;
  align-items: center;
  height: 60px;
  text-decoration: none;
  list-style: none;
}

.nav-item > .nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  list-style: none;
  font-size: 1rem;
  height: 55px;
  /* font-weight: 600;
  font-size: 1.3rem; */
}

.nav-item > .scroll_nav_links {
  color: #000;
  display: flex;
  align-items: center;
  text-decoration: none;
  list-style: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  transition: var(--trans);
  height: 10vh;
  /* font-weight: 600;
  font-size: 1.3rem; */
}

.nav-subitem > a {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 1rem;
}

.btn-outline-light:hover {
  border: 1px solid var(--primary) !important;
  background: var(--secondary) !important;
  color: #fff !important;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.active_border_bottom {
  box-shadow: 0px 4px 0px 0px #fff;
  z-index: 2;
}

.scroll_active_border_bottom {
  box-shadow: 0px 4px 0px 0px var(--primary_dark);
  z-index: 2;
}

// .nav-links {
//   text-decoration: none;
//   padding: 0.5rem 1rem;
// }

/* .nav-links.active {
  color: #fff;
  background-color: var(--primary);
  border-radius: 4px;
  transition: all 0.2s ease-out;
} */

/* .nav-links:hover {
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.2s ease-out;
} */

.fa-bars {
  color: black;
  font-size: 2rem;
}

.menu-icon {
  display: none;
}

.dd_mobile_180 {
  transform: rotateX(180deg);
  transition: 0.2s ease-in-out;
}

.scroll_menu_icon {
  display: none;
}

.navbar_subitem {
  z-index: 5;
}

.navbar_right_buttons {
  @include flex_justify_align_center;
  gap: 1.5rem;
  button {
    white-space: nowrap;
  }
}


.airdropbtn{
  color: #000;
  display: flex;
  align-items: center;
  text-decoration: none;
  list-style: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  transition: var(--trans);
  cursor: pointer;
}

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

@media screen and (max-width: 1050px) and (min-width: 600px) {
  .nav-menu {
    width: 40vw !important;
  }

  .mobile_navbar_logo img {
    width: 30% !important;
  }
}

@media screen and (max-width: 1050px) {
  .navbar_right_buttons {
    display: none;
  }

  .nav-item > .nav-links {
    color: #000;
  }

  .scroll_two_line {
    color: var(--primary);
  }

  .mobile_navbar_logo {
    // padding: 0 40px;
    height: 10vh;
    box-shadow: 0 0.1px 5px var(--primary_text);
    width: 100%;
    display: flex;
    align-items: center;
    text-align: initial;
  }

  .mobile_navbar_logo img {
    width: 70%;
  }

  .nav-item > a {
    color: #000;
    font-weight: 600;
    font-size: 1.3rem;
  }

  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 0.5rem;
    /* width: 100%; */
    height: 100vh;
    position: absolute;
    top: 0;
    justify-content: start;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    // padding-left: 0 !important;
  }

  .nav-menu.active {
    background: var(--primary_bg);
    left: 0;
    top: 0;
    opacity: 1;
    /* transition: all 0.5s ease; */
    z-index: 2;
    width: 70%;
  }
  .nav-links {
    text-align: left;
    // margin: 0px 20px 0px 40px;
    // width: 100%;
    display: table;
    padding: 0rem 0rem;
    // height: 60px;
    display: flex;
    align-items: center;
  }
  .nav-links-submenu {
    text-align: left;
    padding: 0px 10px 0px 60px;
    width: 100%;
    color: black;
    text-decoration: none;
    display: table;
  }

  .nav-links-submenu-title {
    text-align: left;
    padding: 0px 10px 0px 50px;
    width: 100%;
    color: black;
    text-decoration: none;
    display: table;
    font-weight: 600;
  }

  .nav-links-submenu:hover {
    color: #fff;
    /* background-color: var(--primary); */
    border-radius: 0;
  }
  .nav-links:hover {
    /* background-color: var(--primary); */
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    color: #fff;
    display: block;
    position: absolute;
    top: 8px;
    left: 1rem;
    font-size: 2rem;
    cursor: pointer;
  }

  .scroll_menu_icon {
    display: flex;
    color: var(--primary);
    top: 8px;
    right: 1rem;
    font-size: 2rem;
    cursor: pointer;
    transition: var(--trans);
  }

  .fa-times {
    color: black;
    font-size: 2rem;
  }
  /* .my_navbar {
    justify-content: space-between;
  } */
  // .nav-menu {
  //   align-items: flex-start;
  // }
  /* .nav-item {
    height: 25px;
  } */

  .navbar_subitem {
    display: none;
  }

  .scroll_menu_icon ion-icon {
    font-size: 2.5rem;
  }

  .dd_mobile_180 {
    transform: rotateX(180deg);
    transition: 0.2s ease-in-out;
  }
}
