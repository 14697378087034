.s3_header {
  padding: 2rem 0rem 1rem 0;
}

.s3_body {
  background: var(--primary);
  padding: 2rem;
  color: #fff;
  border-radius: 12px;
  position: relative;
}

.s3_left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5rem 0;
}

.s3_right {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 16rem;
    position: absolute;
    bottom: 50;
  }
}

@media screen and (max-width: 1050px) {
  .s3_body {
    padding: initial;
  }

  .s3_left {
    padding: 1rem;
    height: 45rem;
    justify-content: start;
    text-align: center;
  }

  .s3_right img {
    width: 90%;
  }
}
