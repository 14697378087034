.hero_right {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero_right img:nth-child(1) {
  z-index: 1;
  width: 70%;
}

.hero_right img:nth-child(2) {
  width: 80%;
  object-fit: contain;
  position: absolute;
}
.hero_right img:nth-child(3) {
  width: 30%;
  object-fit: contain;
  position: absolute;
  transform: translate(5rem, 4rem);
  animation: heroScale1 5s ease-in-out infinite;
}
.hero_right img:nth-child(4) {
  width: 25%;
  object-fit: contain;
  position: absolute;
  transform: translate(4rem, -7rem);
  animation: heroScale2 3s ease-in-out infinite;
}
.hero_right img:nth-child(5) {
  width: 12%;
  object-fit: contain;
  position: absolute;
  transform: translate(-7rem, 2rem);
  animation: heroScale3 10s ease-in-out infinite;
}
.hero_right img:nth-child(6) {
  width: 27%;
  object-fit: contain;
  position: absolute;
  transform: translate(-5rem, -6rem);
  animation: heroScale4 4s ease-in-out infinite;
}

.hero_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: 1rem;
}

.hero_badge {
  display: flex;
  gap: 1rem;
  align-items: center;
  img {
    width: 8rem;
  }
}

.hero_coin_btn {
  h5 {
    margin-bottom: 0;
    font-weight: 600;
  }
}


.buymaticbtn{
  width: 50%;
  // float: right;
  cursor: pointer;
  margin-left: 47%;
  // align-self: flex-end;
}
.ortext{
  margin-top: 1%;
  color: #000;
  font-weight: 500;
  margin-left: 10%;
}
.buybtnfirstrow{
  width: 27%;
  cursor: pointer;
  padding: 5px;
}




@keyframes heroScale1 {
  0% {
    transform: translate(5rem, 4rem) scale(1);
  }
  50% {
    transform: translate(5rem, 4rem) scale(1.2);
  }
  100% {
    transform: translate(5rem, 4rem) scale(1);
  }
}

@keyframes heroScale2 {
  0% {
    transform: translate(4rem, -7rem) scale(1);
  }
  50% {
    transform: translate(4rem, -7rem) scale(1.2);
  }
  100% {
    transform: translate(4rem, -7rem) scale(1);
  }
}

@keyframes heroScale3 {
  0% {
    transform: translate(-7rem, 2rem) scale(1);
  }
  50% {
    transform: translate(-7rem, 2rem) scale(3);
  }
  100% {
    transform: translate(-7rem, 2rem) scale(1);
  }
}

@keyframes heroScale4 {
  0% {
    transform: translate(-5rem, -6rem) scale(1);
  }
  50% {
    transform: translate(-5rem, -6rem) scale(1.5);
  }
  100% {
    transform: translate(-5rem, -6rem) scale(1);
  }
}

@media screen and (max-width: 1050px) {
  // .hero_right img:nth-child(1) {
  //   width: 100%;
  // }
  .hero_left {
    align-items: center;
    text-align: center;
    gap: 0.5rem;
  }

  .hero_right img:nth-child(1) {
    width: 50%;
  }

  .hero_right img:nth-child(2) {
    width: 100%;
  }

 

  .hero_right {
    padding: 4rem 0;
  }

  .hero_badge {
    flex-direction: column;
  }
}
