.login_title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  h3 {
    font-weight: 800;
  }
}

.login_top_padding {
  padding-top: 15vh;
  &.bottom {
    margin-bottom: -5rem;
  }
}

.login_body {
  position: relative;
}

.cc_form {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: 1;
  &.register {
    height: 100vh;
  }
  .left_img {
    position: absolute;
    left: 0;
    z-index: 0;
    width: 50%;
  }
  .right_img {
    position: absolute;
    right: 0;
    z-index: 0;
    width: 50%;
  }
}

.cc_form_container {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding: 2rem;
  background: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  border-radius: 12px;
  gap: 1rem;
  width: 30%;
  padding: 2rem;
  .login_new_register {
    margin-bottom: 0;
    span {
      cursor: pointer;
      color: var(--primary);
    }
  }
  .cc_form_header {
    h5 {
      font-weight: 800;
    }
    p {
      margin-bottom: 0;
    }
  }
}

.cc_form_id,
.cc_form_pwd {
  display: flex;
  flex-direction: column;
}

.cc_form_id {
  input {
    border: none;
    background-color: #eaeaea;
    padding: 10px 15px;
    border-radius: 4px;
  }
}

.cc_form_pwd {
  input {
    border: none;
    background-color: #eaeaea;
    padding: 10px 15px;
    border-radius: 4px;
  }
}

.auth_bg {
  background-color: var(--primary_bg);
}

@media screen and (max-width: 1050px) {
  .left_img {
    display: none;
  }
  .right_img {
    display: none;
  }

  .cc_form_container {
    width: 92%;
  }

  .cc_form {
    height: 70vh;
    &.register {
      height: 75vh;
    }
  }
}
