.s4_right {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.s4_btns {
  display: flex;
  gap: 1rem;
}

.s4_right_one,
.s4_right_two {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 1rem;
    background: #fff;
    box-shadow: var(--box_shadow);
    text-align: center;
    width: 12rem;
    height: 10rem;
    border-radius: 12px;
    h2 {
      font-weight: 800;
      color: var(--primary_dark);
    }
    h5 {
      color: var(--primary);
    }
  }
  &.up {
    transform: translate(0, -1rem);
  }
  &.down {
    transform: translate(0, 2rem);
  }
}

// .s4_right_two {
//   display: flex;
//   flex-direction: column;
//   gap: 1rem;
//   div {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;

//     background: lightgray;
//     text-align: center;
//     width: 10rem;
//     height: 10rem;
//   }
// }



.ratingstars{
  
  display: list-item !important;
}
.stars{
  width: 12%;

}
.revieratings{
  margin-top: 12%;
}

@media screen and (max-width: 1050px) {
  .s4_right_one,
  .s4_right_two {
    div {
      width: 10rem;
      height: 10rem;
    }
    &.up {
      transform: translate(0, 1rem);
    }
    &.down {
      transform: translate(0, 3rem);
    }
  }

  .s4_right_one,
  .s4_right_two {
    gap: 1rem;
  }
  .s4_right {
    gap: 1rem;
  }

  .s4_btns {
    flex-direction: column;
  }
}
