.s6_right {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.s6_left {
  img {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 70%;
    margin: auto;
  }
}

@media screen and (max-width: 1050px) {
  .s6_right {
    align-items: center;
    text-align: center;
    gap: 0.5rem;
  }
}
