.footer_container {
  padding-top: 4rem;
  background: var(--primary_dark);
  color: #fff;
}

.footer_social_media_icons {
  display: flex;
  gap: 1rem;
  &:only-of-type {
    cursor: pointer;
    font-size: 1.5rem;
  }
  a {
    color: #fff;
    transition: var(--trans);
    &:hover {
      color: var(--primary);
    }
  }
}

.footer_copyright {
  padding: 2rem 0;
}

.right,
.left_right {
  h5 {
    text-transform: uppercase;
    margin-bottom: 1rem;
    font-weight: 600;
  }
  p {
    cursor: pointer;
    margin-bottom: 0.5rem;
    &:hover {
      text-decoration: underline;
    }
  }
}

.left_left {
  img {
    cursor: pointer;
    padding-bottom: 0.5rem;
    width: 2.75rem;
  }
}

.footer_a_tag {
  text-decoration: none;
  color: #fff;
  &:hover {
    color: #fff;
    text-decoration: underline;
  }
}

@media screen and (max-width: 1050px) {
  .left,
  .right {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
